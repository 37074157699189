import { Translations } from '../types/translation';
import { enTerms } from './en-terms';

export const EnLanguage: Record<Translations, string> = {
    login: 'Log in',
    logout: 'Log out',
    profile: 'Profile',
    makeOrder: 'Make an order',
    myOrders: 'My orders',
    delete: 'Delete',
    name: 'Name',
    phone: 'Number',
    surname: 'Surname',
    transactions: 'Transactions',
    uploadPhoto: 'Upload a photo',
    userSettings: 'User Settings',
    email: 'Email',
    save: 'Save',
    transactionsEmpty: 'The transaction list is empty',
    addressSettings: 'Setting up the delivery address',
    apartmentNumber: 'Apartment number',
    city: 'City/Town',
    country: 'Country',
    district: 'District',
    entrance: 'Entrance',
    houseNumber: 'House number',
    region: 'Region',
    street: 'Street',
    cancel: 'Cancel',
    userDeleteContent: 'After deletion, the account will become unavailable',
    userDeleteTitle: 'Do you really want to delete the account?',
    applicationCompleted: 'Application is completed',
    deliveryDate: 'Delivery data',
    direction: 'Direction',
    fromWhom: 'From',
    paid: 'Paid',
    sendTime: 'Sending time',
    shippingCompany: 'Transfer company',
    status: 'Status',
    toWhom: 'To',
    trackingNumber: 'Tracking number',
    editOrder: 'Edit order',
    payOrder: 'Pay order',
    payOrderTitle: 'Pay order №',
    currency: 'Currency',
    sum: 'Amount',
    pay: 'Pay',
    orderFrom: 'From',
    orderPackageCost: 'Price',
    orderPackageHeight: 'Height',
    orderPackageItemsCount: 'Number of items',
    orderPackageLength: 'Length',
    orderPackageName: 'Title',
    orderPackageWeight: 'Weight',
    orderPackageWidth: 'Width',
    orderPackageInformation: 'Parcel info',
    orderTo: 'To',
    back: 'Back',
    next: 'Next',
    update: 'Update',
    checkout: 'Make an order',
    orderConfirmation: 'Confirmation',
    orderFiller: 'Filler',
    orderFromTo: 'From-To',
    orderRecipient: 'Receiver',
    orderSender: 'Sender',
    orderTransportation: 'Transportation',
    orderDeliveryToCompanyTypeTitle: 'Option of transfer to a Transport Company',
    orderFillerEmail: 'Filler email',
    orderPayer: 'Payer',
    orderMemberRecipientEmail: 'Receiver email',
    orderMemberRecipientFullAddress: 'Full receiver address',
    orderMemberRecipientName: 'Receiver Name',
    orderMemberRecipientPhone: 'Receiver phone number',
    orderMemberRecipientPostalCode: 'Receiver postal code',
    orderMemberRecipientSurname: 'Receiver surname',
    orderMemberRecipientMiddlename: 'Receiver middle name',
    orderMemberRecipientCity: "Recipient's city",
    orderMemberRecipientStreet: "Recipient's street",
    orderMemberRecipientHouse: "Recipient's house number",
    orderMemberRecipientBuilding: "Recipient's building number",
    orderMemberRecipientApartment: "Number of the recipient's apartment/apartments",
    orderMemberSenderEmail: 'Sender email',
    orderMemberSenderFullAddress: 'Full sender address',
    orderMemberSenderName: 'Sender Name',
    orderMemberSenderPhone: 'Sender phone number',
    orderMemberSenderPostalCode: 'Sender postal code',
    orderMemberSenderSurname: 'Sender surname',
    orderMemberSenderMiddlename: 'Sender middle name',
    orderMemberSenderCity: "Sender's city",
    orderMemberSenderStreet: "Sender's Street",
    orderMemberSenderHouse: "Sender's house number",
    orderMemberSenderBuilding: "Sender's building number",
    orderMemberSenderApartment: "Sender's apartment number",
    orderMemberPassport: 'Passport',
    orderMemberPassportDate: 'Date of Issue',
    orderMemberPassportDivisionCode: 'Division Code',
    orderMemberPassportIssuingAuthority: 'Issuing Authority',
    orderMemberPassportNumber: 'Passport ID Number',
    orderMemberPassportSerialNumber: 'Passport ID Serial Number',
    orderCancel: 'Cancel',
    orderConfirm: 'Order confirmation',
    userInfoUpdatedMessage: 'The information successfully updated',
    userPhotoUpdatedMessage: 'The photo successfully uploaded',
    no: 'No',
    yes: 'Yes',
    authorization: 'Authorization',
    authorizationByEmail: 'by email',
    authorizationByPhone: 'by phone number',
    authorizationType: 'Authorization type',
    passwordForgot: 'Forget password?',
    registration: 'Registration',
    password: 'Password',
    field: 'Field',
    isMandatory: 'is mandatory',
    enteredIncorrectly: 'wrong value',
    confirmPassword: 'Password confirmation',
    register: 'Register',
    passwordsDoNotMatch: 'The entered passwords do not match',
    registrationRejected: 'Registration is canceled',
    registrationRejectedMessage: 'You have not accepted the terms of use of the service Telega',
    serviceName: 'Telega',
    termsOfService: 'The terms of use of the service',
    passwordRestore: 'Password Recovery',
    passwordRestoreMessage: 'Instructions for password recovery have been sent to your email:',
    restore: 'Recover',
    accept: 'Accept',
    welcome: 'Welcome',
    goToOrdersList: 'Go to the list of orders',
    orderPlacedSuccessfully: 'The order has been placed successfully!',
    yourOrderNumber: 'Order number:',
    httpRequestUnexpectedError: 'Unexpected error',
    httpRequestServeError: 'Data loading error',
    httpRequestUserNotAuthorized: 'The user is not logged in',
    httpRequestUserNotFound: 'The requested object was not found',
    termsContent: enTerms,
    panelTitle: 'Admin Panel',
    loginField: 'Username',
    activeOrdersCount: 'Number of active orders',
    ordersCount: 'Number of orders',
    transactionType: 'Transaction type',
    date: 'Date',
    clients: 'Clients',
    finances: 'Finance',
    ordersInfo: 'Information about shipment',
    serviceLogs: 'Logs',
    clientInfo: 'Client info №',
    clientOrdersList: 'List of client orders:',
    clientsList: 'List of clients',
    transactionInfo: 'Transaction Information №',
    financialBalance: 'Financial balance:',
    transactionPayer: 'Payer',
    serviceLogsType: 'Operation type',
    time: 'Time',
    user: 'User',
    exportToExcel: 'Excel export',
    orderInfo: 'Order info №',
    fileDownloadedSuccessfully: 'The file has been successfully downloaded',
    fileDownloadError: 'File download error',
    orderCreatedDate: 'Checkout date',
    serviceCommissions: 'Service commission',
    deleteConfirmation: 'Do you really want to delete the selected entry?',
    confirmAction: 'Confirm the action',
    logicalOperation: 'Logical operation',
    prices: 'Prices',
    price: 'Price',
    rusPostCodes: 'Track numbers',
    deliveryType: 'Type of delivery',
    deliveryTypes: 'Types of delivery',
    category: 'Category',
    value: 'Value',
    deliveryTime: 'Time of delivery',
    code: 'Code',
    maxLength: 'Maximum length',
    maxVolume: 'Maximum volume',
    overMaxLengthPrice: 'Price over the maximum size',
    overMaxVolumePrice: 'Price in excess of the maximum volume',
    overMaxWeightPrice: 'Price over the maximum weight',
    deDeliveryTime: 'Delivery time (German)',
    deName: 'Name (German)',
    enDeliveryTime: 'Delivery time (English)',
    enName: 'Name (English)',
    ruDeliveryTime: 'Delivery time (Russian)',
    ruName: 'Name (Russian)',
    countries: 'Countries',
    transportation: 'Transportation',
    transportationPrice: 'The price of transportation',
    transportationPrices: 'Transportation prices',
    orderNumber: 'Order number',
    importSucceed: 'Import completed successfully',
    trackingNumberSelectedAt: 'Linked to an order',
    system: 'System',
    additionalPrice: 'Additional price',
    additionalPricePerKilogram: 'Additional price per kilogram',
    placeholderSearch: 'Search',
    deliveryCompany: 'Delivery company',
    hidden: 'Hidden',
    commissionValue: 'Commission amount',
    deliveryTypeCommission: 'Delivery type commission',
    customsDuties: 'Customs duties',
    generalSettings: 'General settings',
    cdekPhone: 'CDEK Phone',
    orderQrInfo: 'Please, download the QR code on your device. Pack your parcel and bring it to the nearest GLS Pick-up point in your city. Show the code on your device to the employee of GLS, they will scan the code and attach the address label to the parcel.',
    download: 'Download',
    manifest: 'Manifest',
    parcelInsurance: 'Parcel insurance',
    insurancePrice: 'Insurance cost',
    warehouses: 'Warehouses',
    address: 'Address',
    companyName: 'Company name',
    postalCode: 'Postal code',
    workMode: 'Working mode',
    deWorkMode: 'Working mode (German)',
    enWorkMode: 'Working mode (English)',
    ruWorkMode: 'Working mode (Russian)',
    orderType: 'Type of order',
    promoCodes: 'Promo codes',
    promoCode: 'Promo code',
    amountOfDiscount: 'Amount of discount',
    description: 'Description',
    promoCodeExpiresAt: 'Valid until',
    promoCodeStartsAt: 'Valid from',
    fulfillment: 'Fulfillment',
    purchaseName: 'Name of Purchase',
    shopName: 'Name of Shop',
    warehouse: 'Warehouse',
    predefinedAddresses: 'Predefined addresses',
    predefinedAddress: 'Predefined address',
    representative: 'Name of the representative',
    house: 'House number',
    building: 'Building number',
    apartment: 'Apartment number',
    perItem: 'per item',
    goodName: 'Productname',
    goods: 'Goods',
    orderPackageItemUrl: 'Link to the product',
    purchases: 'Purchases',
    productAdding: 'Adding a product',
    goodsList: 'List of goods',
    expressShipment: 'Express-Shipment',
    regularShipment: 'Regular Shipment',
    itemTotalCost: 'Total cost of this item',
    maxGirth: 'Maximum girth',
    maxWeight: 'Maximum weight',
    parcelDimensionsAttentionInformation: 'Please enter the dimensions and weight of the parcel correctly! In case of discrepancy between the declared and actual data, the cost of the service will be adjusted.',
    sanctionedGoodsInfo: 'Kindly ask you to read the list of goods prohibited for import into the Russian Federation',
    client: 'Client',
    promoCodeType: 'Promo code type',
    china: 'China',
    orderPackageCode: 'Product code',
    orderPackageRuName: 'Package contents (Russian)',
    purchaseAdding: 'Adding a purchase',
    addPurchase: 'Add a purchase',
    paidDataUpdated: 'Order details updated. Notify third party services? (RusPost, RusPostWMS)',
    notify: 'Notify',
    makeOrderPaid: 'Set order as paid',
    changelogs: 'Changelogs',
    confirm: 'Confirm',
    orderCancelConfirmation: 'Do you really want to cancel order?',
    promoCodeLimit: 'Limit on number of uses',
    usesCount: 'Number of uses',
};
